import React from 'react';
import classNames from 'classnames';

const Tooltip = ({ children, text }) => (
  <>
    <div className="hover:relative flex flex-col items-center group">
      {children}
      <div className="absolute rounded hidden mt-5 group-hover:flex right-[-10px] bottom-[30px]">
        <span
          className={classNames(
            'w-max relative z-100 p-2 text-[10px] leading-none',
            'whitespace-no-wrap shadow-lg rounded bg-black text-white',
          )}
        >
          {text}
        </span>
      </div>
    </div>
  </>
);

export default Tooltip;
