/*
 * Forbidden Messages
 *
 * This contains all the text for the Forbidden component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.pages.Forbidden';

export default defineMessages({
  body: {
    id: `${scope}.body`,
    defaultMessage: 'You are not authorized to view this page. Contact your administrator',
  },
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Forbidden!',
  },
  home: {
    id: `${scope}.home`,
    defaultMessage: 'Go Home',
  },
});
