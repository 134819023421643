/**
 *
 * Breadcrumb
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { HomeIcon } from '@heroicons/react/solid';

export function Breadcrumb(props) {
  const { items } = props;
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center lg:space-x-1">
        <li>
          <div>
            <a href="/" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </a>
          </div>
        </li>
        {items.map(item => (
          <li key={item.key}>
            <div className="flex items-center">
              <svg
                className="flex-shrink-0 h-5 w-5 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              <a
                href={item.href}
                className={classNames(
                  'ml-1 lg:ml-2 text-sm font-medium',
                  item.href
                    ? 'text-gray-500 hover:text-gray-700 cursor-pointer'
                    : 'text-gray-400 cursor-not-allowed pointer-events-none',
                )}
                aria-current={item.current ? 'page' : undefined}
              >
                {item.label}
              </a>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
      current: PropTypes.bool,
      key: PropTypes.string,
    }),
  ),
};
Breadcrumb.defaultProps = {
  items: [],
};

export default memo(Breadcrumb);
