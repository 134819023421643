/*
 * NotFound Messages
 *
 * This contains all the text for the NotFound component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.pages.NotFound';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Oh My ..!',
  },
  body: {
    id: `${scope}.body`,
    defaultMessage: 'It looks like this page has been lost',
  },
  home: {
    id: `${scope}.home`,
    defaultMessage: 'Go Home',
  },
});
