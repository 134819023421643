/**
 *
 * Empty
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Img from 'images/404.svg';
import classNames from 'classnames';
import messages from './messages';

export function Empty(props) {
  const { fullScreen, message } = props;
  return (
    <div className={classNames(' bg-white', fullScreen ? 'h-screen relative w-screen' : '')}>
      <div className="h-full flex justify-center items-center ">
        <div className="md: flex   items-center p-2 rounded-md bg-white">
          <div className="flex flex-col space-y-1 flex-shrink-0 w-full justify-center align-middle items-center">
            <img
              src={Img}
              alt="Not Found"
              className="md:max-w-xl lg:max-w-xl md:h-full hidden md:block -mt-20 mr-10 -mb-15"
            />
            <h2 className="flex text-lg leading-3 font-medium text-slate-500  text-center italic">
              {message || <FormattedMessage {...messages.header} />}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}

Empty.propTypes = {
  fullScreen: PropTypes.bool,
  message: PropTypes.element,
};
Empty.defaultProps = {
  fullScreen: false,
};

export default memo(Empty);
