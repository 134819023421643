/*
 * NotFound Messages
 *
 * This contains all the text for the NotFound component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Pagination';

export default defineMessages({
  previous: {
    id: `${scope}.previous`,
    defaultMessage: 'Previous',
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: 'Next',
  },
  results: {
    id: `${scope}.results`,
    defaultMessage: 'Showing {from} to {to} of {total} {items}',
  },
});
