import { memoize, get } from 'lodash';
import { gql } from '@apollo/client';

import Auth from 'utils/auth';

export const GET_PROFILE_PIC = gql`
  query profilePic($id: Int!) {
    getProfilePic(id: $id) {
      url
    }
  }
`;
export const getImage = memoize(async ({ id }) => {
  const client = await Auth.client();
  const { data } = await client.query({
    query: GET_PROFILE_PIC,
    variables: {
      id,
    },
  });
  return get(data, 'getProfilePic.url');
});
