/**
 *
 * BtnGroup
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { map, get } from 'lodash';
import ReactTooltip from 'react-tooltip';

import classNames from 'classnames';

export function ButtonGroup(props) {
  const { items, size } = props;
  const sizeMap = {
    lg: '2xl:px-4 2xl:py-2 text-xs 2xl:text-base font-medium ',
    md: '2xl:px-3 2xl:py-1.5 px-1 py-1 text-sm font-medium',
    sm: 'px-2 py-0.5 text-xs font-light',
    xs: 'px-1 py-0.5 text-xs font-light',
  };
  const iconSizeMap = {
    lg: 'h-5 w-5',
    md: 'h-4 w-4',
    sm: 'h-3 w-3',
    xs: 'h-2 w-2',
  };
  return (
    <span className="inline-flex shadow-sm rounded-md">
      {map(items, (item, idx) => (
        <button
          key={item.key}
          type="button"
          onClick={item.onClick}
          disabled={item.disabled}
          className={classNames(
            idx === 0 ? 'rounded-l-md' : '',
            idx + 1 === items.length ? 'rounded-r-md' : '',
            get(sizeMap, size, 'px-2 py-1.5'),
            item.disabled ? 'cursor-not-allowed bg-zinc-100 text-gray-400' : 'cursor-pointer bg-white text-gray-700',
            'inline-flex items-center  border border-gray-300',
            'hover:bg-gray-50 focus:outline-none',
            'focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500',
          )}
        >
          <ReactTooltip id={item.key} place="bottom" disable={!item.tooltip} effect="solid">
            {item.tooltip}
          </ReactTooltip>
          <div className="flex items-center xl:px-1" data-tip={!!item.tooltip} data-for={item.key}>
            {item.LeadingIcon && <item.LeadingIcon className={classNames(get(iconSizeMap, size, 'h-3 w-3'), 'mr-1')} />}
            {item.label}
          </div>
        </button>
      ))}
    </span>
  );
}

ButtonGroup.propTypes = {
  size: PropTypes.oneOf(['sm', 'lg', 'md', 'xs']),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
      LeadingIcon: PropTypes.object,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
      tooltip: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.bool]),
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
};

ButtonGroup.defaultProps = {
  size: 'md',
};

export default memo(ButtonGroup);
