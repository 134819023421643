/*
 * Maintenance Messages
 *
 * This contains all the text for the Maintenance component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.pages.Maintenance';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'We are tuning up a few things',
  },
  body: {
    id: `${scope}.body`,
    defaultMessage: 'Talview is currently undergoing planned maintenance. We apologize for the inconvenience',
  },
  update: {
    id: `${scope}.update`,
    defaultMessage: 'Check for Updates',
  },
});
